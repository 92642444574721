import type {NextPage} from 'next';
import Head from 'next/head';
import {Box, Button, Container, Typography, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useRouter} from "next/router";

const NotFound: NextPage = () => {
  const theme = useTheme();
  const router = useRouter()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Head>
        <title>
          Error: Not Found | Material Kit Pro
        </title>
      </Head>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          flexGrow: 1,
          py: '80px'
        }}
      >
        <Container maxWidth="lg">
          <Typography
            align="center"
            variant={mobileDevice ? 'h4' : 'h1'}
          >
            404: The page you are looking for isn’t here
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 0.5 }}
            variant="subtitle2"
          >
            You either tried some shady route or you
            came here by mistake. Whichever it is, try using the
            navigation.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6
            }}
          >
            <Box
              alt="Under development"
              component="img"
              src={`/static/error/error404_${theme.palette.mode}.svg`}
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 400
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                router.back()
              }}
            >
              Retour
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NotFound;
